// Refer to https://create-react-app.dev/docs/adding-a-css-modules-stylesheet
// On how to use scss modules with React

// Import WMN Design System scss for use in this scss file
// Tilde (~) represents the root folder(src)
@import '~assets/wmnds/vars';
@import '~assets/wmnds/mixins';

$duration: 0.75s;

.textBg {
  animation: fade $duration ease-out forwards;

  & ~ path {
    // color: $white;
    fill: $white;
    stroke: $white;
    stroke-width: 0.25;
  }

  // Change the airport icon to white if it is a child of selected station
  & ~ [id='Airport'] {
    fill: $white;
  }
}

// .selectedStation {
//   color: $white;
//   background: get-color(primary);
// }

.mapView {
  position: relative;
}

.mapView {
  max-height: 70vh;

  @media (min-width: $breakpoint-md) {
    position: sticky;
    top: 0;
    margin: $size-md;
  }

  // CSS hack to make the map container square
  &::before {
    content: '';
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 100%;
  }
}

.mapContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: $size-lg;
  // overflow: hidden;
}

.zoneSelected {
  path:first-child {
    fill: get-color(secondary, 70);
  }
}

.selectedZone {
  fill: get-color(secondary, 70) !important;
}

.stationSelected {
  fill: get-color(primary) !important;
}

.hideStations {
  & > g:not(.showStation):not(.selectedStation) {
    display: none;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
