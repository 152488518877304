@import '~assets/wmnds/vars';
@import '~assets/wmnds/mixins';

.mapViewWrapper {
  position: absolute;
  top: -10px;
  width: 100%;
  height: 72px;
  border: 0;
  background-color: $white;
  text-align: center;

  @media (min-width: $breakpoint-md) {
    display: flex;
    z-index: 10;
    top: auto;
    right: $size-md;
    bottom: -2.75rem;
    width: 308px;
    border: 1px solid get-color(borderCol);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
  }
}

.content {
  display: flex;
  width: 100%;

  @media (min-width: $breakpoint-md) {
    width: 100%;
    // justify-content: center;
  }
}

.listViewWrapper {
  position: static;
  // width: 288px;
  height: 132px;

  h3 {
    color: get-color(primary);
    font-size: $size-lg;
  }

  .content {
    @media (min-width: $breakpoint-md) {
      width: 100%;
    }
  }
}
